@import "../base/variables";
@import "../radix/mixins";

body.milner-gardens{

  // Body font
  font-family: Georgia, Times, "Times New Roman", serif;

  p, ol, ul{
    font-family: Georgia, Times, "Times New Roman", serif;
  }


  @include breakpoint("md"){
    .viu_title{
      font-size:2em;
    }
  }

  .main-title-preface{
    font-size: 0.6em;
    letter-spacing: 0em;
    display: block;
  }

  h1.homepage-tagline, .secondary-font{
    font-family: 'Dancing Script', cursive;
    font-weight: normal;
  }

  .milner-brand{
    @media (min-width: $screen-lg-min) {
      // Make the logo a tad larger at desktop.
      width: 10.33%;
      margin-left: -2%;
    }
  }

  .viuFooter__logo{
    max-width: 250px;
    @media (min-width: $screen-sm) {
      max-width: 350px;
    }
    @media (min-width: $screen-md) {
      max-width: 400px;
    }
  }
}
