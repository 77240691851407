/* line 4, /home/dwoods/work/sites/campus/sites/all/themes/viu_campus_theme/scss/site/milner_gardens.scss */
body.milner-gardens {
  font-family: Georgia, Times, "Times New Roman", serif;
}

/* line 9, /home/dwoods/work/sites/campus/sites/all/themes/viu_campus_theme/scss/site/milner_gardens.scss */
body.milner-gardens p, body.milner-gardens ol, body.milner-gardens ul {
  font-family: Georgia, Times, "Times New Roman", serif;
}

@media (min-width: 992px) {
  /* line 15, /home/dwoods/work/sites/campus/sites/all/themes/viu_campus_theme/scss/site/milner_gardens.scss */
  body.milner-gardens .viu_title {
    font-size: 2em;
  }
}

/* line 20, /home/dwoods/work/sites/campus/sites/all/themes/viu_campus_theme/scss/site/milner_gardens.scss */
body.milner-gardens .main-title-preface {
  font-size: 0.6em;
  letter-spacing: 0em;
  display: block;
}

/* line 26, /home/dwoods/work/sites/campus/sites/all/themes/viu_campus_theme/scss/site/milner_gardens.scss */
body.milner-gardens h1.homepage-tagline, body.milner-gardens .secondary-font {
  font-family: 'Dancing Script', cursive;
  font-weight: normal;
}

@media (min-width: 1200px) {
  /* line 31, /home/dwoods/work/sites/campus/sites/all/themes/viu_campus_theme/scss/site/milner_gardens.scss */
  body.milner-gardens .milner-brand {
    width: 10.33%;
    margin-left: -2%;
  }
}

/* line 39, /home/dwoods/work/sites/campus/sites/all/themes/viu_campus_theme/scss/site/milner_gardens.scss */
body.milner-gardens .viuFooter__logo {
  max-width: 250px;
}

@media (min-width: 768px) {
  /* line 39, /home/dwoods/work/sites/campus/sites/all/themes/viu_campus_theme/scss/site/milner_gardens.scss */
  body.milner-gardens .viuFooter__logo {
    max-width: 350px;
  }
}

@media (min-width: 992px) {
  /* line 39, /home/dwoods/work/sites/campus/sites/all/themes/viu_campus_theme/scss/site/milner_gardens.scss */
  body.milner-gardens .viuFooter__logo {
    max-width: 400px;
  }
}
